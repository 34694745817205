.box{
    background-color: #fff;
    border: 1px solid #eaedf3;
    border-radius: 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
}

.box__header{
    padding: 20px 16px;

    @include media-up(md){
        padding: 28px 32px;
    }
}

.box__bit{
    padding: 20px 16px;

    @include media-up(md){
        padding: 20px 32px;
    }
}
