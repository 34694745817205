/*
    **  LAYOUT
*/
.root__container{

}

.content{
    width: 100%;
    padding: 86px 16px 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-up(lg){
        padding: 112px 36px 0 301px;
    }
}

.content__body{
    margin-bottom: 54px;
}

body{
    font-family: 'Roboto', sans-serif;
    color: #3e3f42;
}

hr{
    border-color: #eaedf3;
}

/*
    **  MINI COMPONENTS
*/
.main_logo{
    width: 113px;
}

.border-grey-1px{
    border: 1px  solid #eaedf3;
}

.icon_alert{
    width: 103px;

    @media screen and (min-width: 480px) {
        width: 190px;
    }
}
