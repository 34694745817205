.main__header{
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 16px;
    height: 70px;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    transition: box-shadow 200ms;
    z-index: 2;
    justify-content: space-between;
    border-bottom: 1px solid #eaedf3;

    @include media-up(md){
        padding: 0 32px;
    }

    @include media-up(lg){
        justify-content: space-between;
        align-items: flex-start;
        height: 90px;
        padding: 38px 36px 0 301px;
        z-index: 1;
        border-bottom: 0;
    }

    &.scrolled{
        border-bottom: 1px solid transparent;
        padding-top: 0;
        align-items: center;
        box-shadow: inset 1px 0 0 #dadce0, inset 0px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.2), 0 1px 3px 1px rgba(60,64,67,.1);
    }
}
