.wrapper__filters{
    position: fixed;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    box-shadow: inset 1px 0 0 #dadce0, inset 0px 0 0 #dadce0, -21px 1px 0px 0 rgba(60, 64, 67, 0.2), 0 1px 3px 1px rgba(60, 64, 67, 0.1);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px;
    bottom: -100%;
    opacity: 0;
    transition: all 200ms;

    &.toggled{
        bottom: 0;
        opacity: 1;
    }

    @include media-up(lg){
        position: initial;
        box-shadow: none;
        display: block;
        padding: 0;
        opacity: 1;
    }
}
