.main__aside{
    max-width: 270px;
    box-shadow: 1px 0px 0px 0px rgba(234, 237, 243, 0.004);
    border: 1px solid #eaedf3;
    z-index: 2;
    transform: translateX(-100%);

    @include media(lg){
        transition: all 200ms;
    }

    @include media-up(lg){
        transform: translateX(0);
    }

    &.toggled {
        transform: translateX(0);
        box-shadow: inset 1px 0 0 #dadce0, inset 0px 0 0 #dadce0, 0 1px 10px 0 rgba(60,64,67,.2), 0 1px 3px 1px rgba(60,64,67,.1);
    }
}

.main__aside__nav__list .nav__item{
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    @include media(lg){
        transition: all 200ms;
    }

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: $blue-1;
        width: 3px;
        height: 100%;
        opacity: 0;
        transition: all .2s;
    }

    &.active{
        background: #f2f6f9;

        span{
            color: $blue-1;
        }

        &::before{
            opacity: 1;
        }
    }

    @include media-up(md){
        padding: 0 32px;
    }

    @include media-up(lg){
        &:hover{
            background: #f2f6f9;

            span{
                color: $blue-1;
            }

            &::before{
                opacity: 1;
            }
        }
    }
}

.main__aside__nav .nav_link{
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
}
