.content__exhibitions .table__render__info thead th:nth-child(6){
    width: 12%;
}

@include media-up(xxl){
    .content__exhibitions .table__render__info thead th{
        &:nth-child(5){
            width: 48%;
        }
    }
}

.rendered-detail-label{
    border: 1px solid #eaedf3;
    width: 100px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #28b33b;
    font-size: 14px;
    font-weight: 500;
}

.grid__uploaded__pics{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(192px  , 1fr));
    grid-gap: 16px;

    @include media-up(lg){
        grid-gap: 32px;
    }

    @include media-up(xl){
        grid-template-columns: repeat(auto-fill, minmax(259px  , 1fr));
    }

    .item .render__img{
        background-color: #e8f4fb;
        border: $d-b-grey;
        height: 158px;
        width: 100%;
    }
}
