.ff-roboto{
    font-family: 'Roboto', sans-serif;
}

.text-white{
    color: #fff;
}

.text-lightgrey-1{
    color: #9ea0a5;
}

.text-grey-1{
    color: #6b6c6f;
}

.text-blue-1{
    color: $blue-1;
}

.text-blue-2{
    color: #1184d0;
}

.text-green-1{
    color: #38b148;
}

.text-red-1{
    color: #e23e3e;
}

.content__title{
    font-size: 18px;
    font-weight: 500;
    color: $blue-1;
}
