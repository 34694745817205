/*
    ** COLORS **
*/

$blue-1        :    #00558D;
$darkgrey-1    :    #222221;
$lightgrey-1   :    #FBFBFB;
$green-1       :    #63A648;

$d-b-grey: 1px solid #eaedf3;
