@include media-up(xxl){
    .content__image .table__render__info thead th{
        &:nth-child(5){
            width: 33%;
        }
        &:nth-child(6){
            width: 21%;
        }
        &:nth-child(7){
            width: 14%;
        }
    }
}
