@include media-up(xxl){
    .content__backorders .table__render__info thead th{
        &:nth-child(6){
            width: 33%;
        }
        &:nth-child(7){
            width: 12%;
        }
    }
}
