.overlay{
    padding-top: 60px;

    &--alert{
        padding-top: 120px;
    }

    @include media-up(lg){
        padding-top: 129px;
    }
}

.lightbox{
    border-radius: 0;
    max-width: 540px;
}

.lightbox--alert{
    max-width: 635px;
    padding: 16px;

    @include media-up(sm){
        padding: 32px 32px 22px;
    }

    @include media-up(md){
        padding: 64px 64px 22px 64px;
    }
}

.close-modal{
    top: 9px;
    color: #cecfd2;

    @include media-up(md){
        top: 14px;
        right: 17px;
    }
}

.lightbox__header{
    padding: 20px 16px;

    @include media-up(md){
        padding: 28px 36px;
    }
}

.lightbox__title{
    font-size: 18px;
    font-weight: 500;
    color: #3e3f42;
    padding-right: 40px;

    @include media-up(sm){
        font-size: 20px;
    }
}

.lightbox__bit{
    padding: 20px 16px;

    @include media-up(md){
        padding: 32px 36px;
    }
}


.lightbox__footer{
    padding: 16px;

    @include media-up(md){
        padding: 20px 36px;
    }
}
