.status{
    font-weight: 500;
    font-size: 14px;
    padding-left: 16px;
    background-position: 0 center;
    background-size: 8px;
    background-repeat: no-repeat;

    &--attended{
        color: #28b33b;
        background-image: url('data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjhweCIgaGVpZ2h0PSI4cHgiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBmaWxsPSJyZ2IoNTIsIDE3MCwgNjgpIgogZD0iTTQuMDAwLC0wLjAwMCBDNi4yMDksLTAuMDAwIDguMDAwLDEuNzkxIDguMDAwLDQuMDAwIEM4LjAwMCw2LjIwOSA2LjIwOSw4LjAwMCA0LjAwMCw4LjAwMCBDMS43OTEsOC4wMDAgLTAuMDAwLDYuMjA5IC0wLjAwMCw0LjAwMCBDLTAuMDAwLDEuNzkxIDEuNzkxLC0wLjAwMCA0LjAwMCwtMC4wMDAgWiIvPgo8L3N2Zz4=');
    }

    &--revision{
        color: #ffa508;
        background-image: url('data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjhweCIgaGVpZ2h0PSI3cHgiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBmaWxsPSJyZ2IoMjU1LCAxNjUsIDgpIgogZD0iTTQuNjU1LDAuMDAwIEw4LjAwMCwwLjAwMCBMOC4wMDAsNi44MDUgTDQuNjU1LDYuODA1IEw0LjY1NSwwLjAwMCBaIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigyNTUsIDE2NSwgOCkiCiBkPSJNMC4xOTUsMC4wMDAgTDMuNTQwLDAuMDAwIEwzLjU0MCw2LjgwNSBMMC4xOTUsNi44MDUgTDAuMTk1LDAuMDAwIFoiLz4KPC9zdmc+');
    }
}
