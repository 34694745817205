.content__historic .table__render__info thead th:nth-child(6){
    width: 12%;
}

@include media-up(xxl){
    .content__historic .table__render__info thead th{
        &:nth-child(2){
            width: 8%;
        }
        &:nth-child(3){
            width: 22%;
        }
        &:nth-child(5){
            width: 39%;
        }
    }
}

.label_year{
    font-size: 14px;
    color: $blue-1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7effc;
    max-width: 50px;
    height: 24px;
    font-weight: 500;
    padding: 0 8px;
}
