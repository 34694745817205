.table__render__info{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #eaedf3;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

    .table__caption{
        border: 1px solid #eaedf3;
        border-bottom: 0;
        padding: 16px 8px;

        @include media-up(md){
            padding: 16px 32px;
        }
    }

    .table__caption__content{
        display: flex;
        flex-direction: column;
        align-items: center;

        .content__title{
            margin-bottom: 16px;
        }

        @include  media-up(sm){
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .content__title{
                margin-bottom: 0;
            }
        }
    }

    .table__thead{
        border: 1px solid #eaedf3;
    }

    .table__heading{
        font-size: 12px;
        font-weight: 500;
        color: #9ea0a5;
        padding: 10px 8px;
        text-transform: uppercase;
        text-align: left;

        &--right{
            text-align: right;
        }

        @include media-up(md){
            padding: 16px 8px;
        }

        @include media-up(lg){
            &:first-child{
                padding-left: 32px;
            }

            &:last-child{
                padding-right: 32px;
            }
        }
    }

    .table__row{
        border-bottom: 1px solid #eaedf3;
        transition: background-color 200ms;

        @include media-up(lg){
            &:hover{
                background-color: #f2f6f9;
            }
        }
    }

    .table__cell{
        padding: 10px 8px;
        font-size: 14px;
        color: #3e3f42;

        @include media-up(md){
            padding: 16px 8px;
        }

        @include media-up(lg){
            &:first-child{
                padding-left: 32px;
            }

            &:last-child{
                padding-right: 32px;
            }
        }
    }

    .wrapper__actions{
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@mixin responsive-table($size) {
    .table__render__info--responsive-#{$size}{
        .table__thead{
            display: none;
        }

        .table__row{
            display: flex;
            flex-direction: column;
        }

        .table__row:nth-of-type(even){
            background-color: #f2f6f9;
        }

        .table__cell{
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: right;
            border-bottom: 1px solid #eaedf3;
        }

        .table__cell::before{
            content: attr(label);
            width: 50%;
            font-size: 12px;
            font-weight: 500;
            color: #9ea0a5;
            text-transform: uppercase;
            text-align: left;
        }
    }
}

@include media(sm){
    @include responsive-table(sm);
}

@media screen and (max-width: 639px){
    @include responsive-table(md);
}

.table__row{
    .wrapper_icon_more{
        display: none;
    }

    .wrapper_icon_trash{
        display: block;
    }
}

@include media-up(xl){
    .table__row{
        .wrapper_icon_more{
            display: block;
        }

        .wrapper_icon_trash{
            display: none;
        }
    }

    .table__row:hover{
        .wrapper_icon_more{
            display: none;
        }

        .wrapper_icon_trash{
            display: block;
        }
    }
}

.form-helper--table-search{
    width: 40px;
}

.form-input--table-search{
    max-width: 224px;
}

.table__rendered__detail{
    border-spacing: 0 16px;
    width: 100%;
    max-width: 390px;

    tr td:first-child{
        width: 100px;
    }
}
