.wizard__steps .step{
    width: 37px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14;
    background-color: #ebeced;

    &:not(:last-child){
        margin-right: 16px;

        @include media-up(sm){
            margin-right: 20px;
        }
    }

    &--completed{
        color: #266292;
        background-color: #e7effb;
    }
}
