/* ROBOTO */
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap'); */

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto/Roboto-Black.ttf');
    font-weight: 900;
}
