.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    border: 0 solid;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background: #F0F0F0;
    transition: all 100ms;
    text-decoration: none;
    min-height: 38px;
    font-size: 12px;

    &:hover{
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    @include media-up(sm){
        font-size: 14px;
    }
}

.btn--white{
    background-image: linear-gradient( rgb(246,247,249) 0%, rgb(255,255,255) 100%);
    border: 1px solid #d8dce6;
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05),inset 0px 2px 0px 0px rgba(255, 255, 255, 0.06);
    color: #3e3f42;
}

.btn--green{
    background-image: linear-gradient( rgb(52,170,68) 0%, rgb(56,178,73) 100%);
    border: 1px solid #2d9c3c;
    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05),inset 0px 2px 0px 0px rgba(255, 255, 255, 0.06);
    color: #fff;
}

.btn--blue-gradient{
    background-image: linear-gradient(rgb(0,84,140) 0%, rgb(5,104,170) 100%);
    border: 1px solid #1469a1;
    color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1),inset 0px 2px 0px 0px rgba(255, 255, 255, 0.06);
}


.btn--blue-1{
    color: #fff;
    background-color: #00538a;
    border: 1px solid transparent;

    @include media-up(lg){
        &:hover{
            background-color: #fff;
            border-color: $blue-1;
            color: $blue-1;
        }
    }
}

.btn--login{
    height: 38px;
}

.btn--new-reg{
    height: 45px;
    max-width: 45px;
    width: 45px;
    border-radius: 100%;
    position: fixed;
    right: 16px;
    bottom: 16px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

    @include media-up(md){
        right: 32px;
    }

    @include media-up(xl){
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        max-width: 95px;
        height: 38px;
        position: initial;
    }
}

.btn--back{
    font-weight: 500;
    font-size: 12px;
    color: #b1b1b1;
    padding-top: 12px;
    transition: all 100ms;
    width: fit-content;

    @include media-up(lg){
        &:hover{
            color: $blue-1;
        }
    }
}
