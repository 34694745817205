.wrapper__form__login{
    width: 100%;
    max-width: 825px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);

    .form-helper{
        z-index: 1;
    }
}

.login_logo{
    width: 100%;
    max-width: 136px;
}
.login_icon_eye{
    width: 20px;
}

.form-label--login{
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #9ea0a5;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.label_error{
    position: absolute;
    opacity: 0;
    right: 0;
    top: -26px;
    color: #e7492e;
    font-size: 14px;
    font-weight: 500;
}

.form-input--login{
    border-radius: 0;
    border-color: #e2e5ed;
    background-color: #fff;
    box-shadow: inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21);
    padding: 0 16px;
    color: #3e3f42;
    font-size: 14px;
    font-weight: 500;

    &.error{
        border-color: #e7492e;
        position: relative;
    }

    &.error + .label_error{
        opacity: 1;
    }
}

.render_bg_login{
    width: 48%;
    height: 100%;

    @include media-up(sm){
        width: 53.9%;
    }
}
