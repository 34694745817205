.form-label{
    font-size: 12px;
    color: #9ea0a5;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
}

.form-input{
    border: 1px solid #d8dce6;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0;

    &::placeholder{
        color: #9ea0a5;
        font-size: 14px;
    }
}

.form-input-select{
    background-image: url("data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjZweCIgaGVpZ2h0PSIxMHB4Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiAgZmlsbD0icmdiKDE1OCwgMTYwLCAxNjUpIgogZD0iTTMuMDQ1LC0wLjAwMCBMNS45NzksMi45MzQgTC0wLjAwMCwzLjA0NSBMMy4wNDUsLTAuMDAwIFpNMi45NDMsOS45OTggTC0wLjAwMCw3LjA1NSBMNS45OTcsNi45NDMgTDIuOTQzLDkuOTk4IFoiLz4KPC9zdmc+");
    background-position: calc(100% - 11px) center;
    padding-right: 30px;
}

[class*='-mark']{
    padding-left: 24px;
}

[class*='-mark']::after{
    background-color: #fff;
}

.form-input-checkbox:checked + .checkbox-mark::after {
    background-size: 9px;
    background-color: #0365a5;
    border: 0;
}

.form-imput--finder{
    height: 46px;
    max-width: 253px;
}


.form-input-uploads{
    font-size: 13px;
    color: #9ea0a5;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px dashed #e2e5ed;
    background-color: #fff;
    height: 46px;
    width: 100%;
    padding: 0 16px;
    cursor: pointer;
}

/*
    **  SELECT SEARCH
*/
.wrapper-select-search{
    position: relative;
}

.select-search{
    display: flex;
    align-items: center;
}

.select-search-dropdown{
    border: $d-b-grey;
    width: 100%;
    position: absolute;
    left: 0;
    top: 45px;
    background-color: #fff;
    margin-bottom: 40px;

    .form-input{
        border-radius: 4px;
    }
}

.select-search-results .item{
    padding: 12px 20px;
    font-size: 14px;

    &:not(:last-child){
        border-bottom: $d-b-grey;
    }
}

.search-results-selected{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -8px;
}

.search-results-selected .item{
    border: $d-b-grey;
    color: #22669c;
    font-size: 14px;
    padding: 2px 8px;
    margin-bottom: 8px;
    transition: all 150ms;

   &:not(:last-child){
       margin-right: 16px;
   }

   @include media-up(lg){
       &:hover{
           border-color: $blue-1;
       }
   }
}
