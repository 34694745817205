.graph__content{
    width: 100%;
}

.graph__content .bar{
    height: 28px;
    width: 100%;
    position: relative;
    animation: render-bar 1 1.8s;

    @include media-up(md){
        height: 44px;
    }

    &:not(:last-child){
        margin-bottom: 38px;

        @include media-up(md){
            margin-bottom: 16px;
        }
    }

    &-value {
        height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        width: 0;
        width: calc(var(--value) * 0.0666666666666667%);
    }

    &-data{
        font-size: 14px;
        color:#9ea0a5;
        position: absolute;
        top: -28px;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        animation-name: render-data;
        animation-duration: 0.2s;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
        opacity: 0;

        @include media-up(md){
            top: 0;
            color: #fff;
            padding: 0 16px;
        }
    }
}

.graph__content .bar{
    &:nth-child(1) .bar-value{
        background-color: #005288;
    }

    &:nth-child(2) .bar-value{
        background-color: #2875a2;
    }

    &:nth-child(3) .bar-value{
        background-color: #5da7c7;
    }

    &:nth-child(4) .bar-value{
        background-color: #70c3d2;
    }

    &:nth-child(5) .bar-value{
        background-color: #7ebed6;
    }
}

@keyframes render-bar{
    0%{
      width: 0px;
    }

    100%{
      width: 100%;
    }
}

@keyframes render-data{
    100%{
        opacity: 1;
    }
}
