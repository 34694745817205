@include media-up(xxl){
    .content__activation .table__render__info thead th{
        &:nth-child(7){
            width: 27%;
        }
        &:nth-child(8){
            width: 12%;
        }
    }
}
