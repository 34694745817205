/*
    ************** BUNDLE URREA SCSS **************
*/

/*
    ** CONFIG/MIXINS MQ **
*/
@import 'bundle_urrea_pdv/config_mixins_mq';

/*
    ** SETTINGS **
*/
@import 'bundle_urrea_pdv/settings/vars';
@import 'bundle_urrea_pdv/settings/fonts';

/*
    ** LAYOUT **
*/
@import 'bundle_urrea_pdv/layout';

/*
    ** COMPONENTS **
*/
@import 'bundle_urrea_pdv/components/buttons';
@import 'bundle_urrea_pdv/components/forms';
@import 'bundle_urrea_pdv/components/usr_auth';
@import 'bundle_urrea_pdv/components/tables';
@import 'bundle_urrea_pdv/components/status';
@import 'bundle_urrea_pdv/components/modal';
@import 'bundle_urrea_pdv/components/icons';
@import 'bundle_urrea_pdv/components/filters';
@import 'bundle_urrea_pdv/components/boxes';

/*
    ** SPECIFIC PAGES **
*/
@import 'bundle_urrea_pdv/pages/login';
@import 'bundle_urrea_pdv/pages/exhibitions';
@import 'bundle_urrea_pdv/pages/maintenance';
@import 'bundle_urrea_pdv/pages/image';
@import 'bundle_urrea_pdv/pages/activation';
@import 'bundle_urrea_pdv/pages/backorders';
@import 'bundle_urrea_pdv/pages/historic';
@import 'bundle_urrea_pdv/pages/catalog';
@import 'bundle_urrea_pdv/pages/fone';

/*
    ** LAYOUT PARTIALS **
*/
@import 'bundle_urrea_pdv/partials/aside_nav';
@import 'bundle_urrea_pdv/partials/header';

/*
    ** URREA UTILITIES **
*/
@import 'bundle_urrea_pdv/utilities/typography';
@import 'bundle_urrea_pdv/utilities/colors';
@import 'bundle_urrea_pdv/utilities/hover';

