/*
    **BACKGOUND COLOR
*/
.bg-white{
    background-color: #fff;
}

.bg-lightgrey-1{
    background-color: $lightgrey-1;
}


.bg-blue-1{
    background-color: $blue-1;
}

/*
    **BORDER  COLOR
*/
.border-grey-1{
    border-color: #eaedf3;
}

/*
    **FILTERS COLOR
*/
.filter-blue-1{
    filter: invert(21%) sepia(50%) saturate(2836%) hue-rotate(184deg) brightness(94%) contrast(97%);
}
