.wraper__thumbnail__usr{
    height: 40px;
    width: 40px;
    border: 1px solid $blue-1;
}

.thumbnail__usr{
    width: 33px;
    height: 33px;
    background-color: #9acbeb;
}

.user__info{
    min-width: 158px;
    top: 58px;
    right: -26px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);

    &::after{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-top: $d-b-grey;
        border-left: $d-b-grey;
        top: -11px;
        right: 34px;
        transform: rotate(45deg);
    }
}
