@include media-up(xxl){
    .content__catalog .table__render__info thead th{
        &:nth-child(4){
            width: 30%;
        }
        &:nth-child(8){
            width: 7%;
        }
    }
}
