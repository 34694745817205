[class*='hover\:']{
    transition: all 70ms;
}

.hover\:-translate-y-4{
    transition: all 150ms;
}

.hover\:border-blue{
    border: 1px solid transparent;
}

@include media-up(xl){
    .hover\:border-blue:hover {
        border: 1px solid $blue-1;
    }

    .hover\:opacity-70:hover{
        opacity: 0.7;
    }

    .hover\:underline:hover{
        text-decoration: underline;
    }

    .hover\:-translate-y-4:hover{
        transform: translateY(-4px);
    }

    .hover\:text-blue:hover{
        color: $blue-1;
    }

    .hover\:text-red:hover{
        color: #e23e3e;
    }

    .hover\:filter-blue-1:hover{
        filter: invert(21%) sepia(50%) saturate(2836%) hue-rotate(184deg) brightness(94%) contrast(97%);
    }
}
